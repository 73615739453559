const useVerificationCodeState = () => {
  return useState<boolean>('verificationCodeState', () => false)
}

const useCompleteProfileState = () => {
  return useState<boolean>('completeProfileState', () => false)
}

export default function useAfterSignup() {
  const verificationCode = useVerificationCodeState()
  const completeProfile = useCompleteProfileState()

  const showVerificationCodeModal = () => {
    verificationCode.value = true
  }

  const hideVerificationCodeModal = () => {
    verificationCode.value = false
  }

  const showCompleteProfileModal = () => {
    completeProfile.value = true
  }

  const hideCompleteProfileModal = () => {
    completeProfile.value = false
  }

  return {
    verificationCode,
    completeProfile,
    showVerificationCodeModal,
    hideVerificationCodeModal,
    showCompleteProfileModal,
    hideCompleteProfileModal,
  }
}
